import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import Blog3 from "../components/img/blog/3"

import B3_1 from "../components/img/blog/3/1"
import B3_2 from "../components/img/blog/3/2"
import B3_3 from "../components/img/blog/3/3"
import B3_4 from "../components/img/blog/3/4"
import B3_5 from "../components/img/blog/3/5"

import BtnWrap from "../components/btn-wrap"
import ImgCon from "../components/img-con"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Čajovnová velmoc Česko? Počet čajoven v ČR a krajích" />
    <h1>Čajovnová velmoc Česko? Počet čajoven v ČR a krajích</h1>
    <Blog3/>
    <br />
    <p>"V Česku je dnes největ&scaron;&iacute; koncentrace čajoven na světě," zaznělo v <a href="https://dvojka.rozhlas.cz/v-cesku-je-dnes-nejvetsi-koncentrace-cajoven-na-svete-pritom-jsme-zemi-bez-7483808">rozhovoru na Dvojce</a> na konci roku 2016 od Ale&scaron;e Juřiny z řetězce <em>Dobr&aacute; čajovna</em>. Tvrd&aacute; data, kter&eacute; by to podložila, v&scaron;ak chyb&iacute;. Lze je z&iacute;skat? Kolik je vůbec čajoven v Česk&eacute; republice? A když už z&iacute;sk&aacute;me data za ČR - li&scaron;&iacute; se koncentrace čajoven v kraj&iacute;ch a krajsk&yacute;ch městech?</p>
    <hr /><h2>Konkr&eacute;tn&iacute; č&iacute;sla o počtu čajoven v Česku</h2>
    <p>Začněme č&iacute;sly za Českou republiku. V cel&eacute;m Česku bylo dle datab&aacute;ze Firmy.cz k &uacute;noru 2020 <strong>celkem 199 čajoven</strong>, což je <strong>1,89 čajoven na 100 tis&iacute;c obyvatel.</strong> Zaj&iacute;mavěj&scaron;&iacute; ale bude, když porovn&aacute;me mezi sebou jednotliv&eacute; kraje.</p>
    <B3_1/>
    <B3_2/>
    <h3>Porovn&aacute;n&iacute; krajů Česk&eacute; republiky</h3>
    <p>Při pohledu do mapy čajoven, kter&aacute; zohledňuje přepočet na 100&nbsp;tis&iacute;c obyvatel, n&aacute;m vych&aacute;z&iacute; při porovn&aacute;n&iacute; krajů jasn&yacute; <strong>&scaron;ampi&oacute;n - Hlavn&iacute; město Praha</strong>. Ž&aacute;dn&eacute; překvapen&iacute;, ta vede i v absolutn&iacute;m počtu čajoven.</p>
    <figure>
    <table>
    <tbody>
    <tr>
    <td>Pořad&iacute;</td>
    <td>Kraj</td>
    <td>Počet obvyatel</td>
    <td>Počet čajoven</td>
    </tr>
    <tr>
    <td>1</td>
    <td>Hlavn&iacute; město Praha</td>
    <td>1267449</td>
    <td>39</td>
    </tr>
    <tr>
    <td>2</td>
    <td>Moravskoslezsk&yacute; kraj</td>
    <td>1213311</td>
    <td>30</td>
    </tr>
    <tr>
    <td>3</td>
    <td>Jihomoravsk&yacute; kraj</td>
    <td>1175025</td>
    <td>29</td>
    </tr>
    <tr>
    <td>4</td>
    <td>&Uacute;steck&yacute; kraj</td>
    <td>822826</td>
    <td>21</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Středočesk&yacute; kraj</td>
    <td>1326876</td>
    <td>14</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Jihočesk&yacute; kraj</td>
    <td>637834</td>
    <td>14</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Olomouck&yacute; kraj</td>
    <td>634718</td>
    <td>9</td>
    </tr>
    <tr>
    <td>8</td>
    <td>Zl&iacute;nsk&yacute; kraj</td>
    <td>584676</td>
    <td>9</td>
    </tr>
    <tr>
    <td>9</td>
    <td>Plzeňsk&yacute; kraj</td>
    <td>576616</td>
    <td>8</td>
    </tr>
    <tr>
    <td>10</td>
    <td>Kr&aacute;lov&eacute;hradeck&yacute; kraj</td>
    <td>551421</td>
    <td>7</td>
    </tr>
    <tr>
    <td>11</td>
    <td>Pardubick&yacute; kraj</td>
    <td>516149</td>
    <td>6</td>
    </tr>
    <tr>
    <td>12</td>
    <td>Karlovarsk&yacute; kraj</td>
    <td>297828</td>
    <td>5</td>
    </tr>
    <tr>
    <td>13</td>
    <td>Libereck&yacute; kraj</td>
    <td>439639</td>
    <td>5</td>
    </tr>
    <tr>
    <td>14</td>
    <td>Kraj Vysočina</td>
    <td>509475</td>
    <td>3</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <p>Zaj&iacute;mavěj&scaron;&iacute;m &scaron;ampi&oacute;nem je v&scaron;ak <strong>&Uacute;steck&yacute; kraj</strong>. Již v absolutn&iacute;m počtu čajoven je nadprůměrn&yacute;, pokud se v&scaron;ak jejich počet přepoč&iacute;t&aacute; na 100 tis&iacute;c obyvatel (Index), um&iacute;st&iacute; se hned <strong>na druh&eacute;m m&iacute;stě</strong>.</p>
    <figure>
    <table>
    <tbody>
    <tr>
    <td>Pořad&iacute;</td>
    <td>Kraj</td>
    <td>Počet obvyatel</td>
    <td>Index</td>
    </tr>
    <tr>
    <td>1</td>
    <td>Hlavn&iacute; město Praha</td>
    <td>1267449</td>
    <td>3.08</td>
    </tr>
    <tr>
    <td>2</td>
    <td>&Uacute;steck&yacute; kraj</td>
    <td>822826</td>
    <td>2.55</td>
    </tr>
    <tr>
    <td>3</td>
    <td>Moravskoslezsk&yacute; kraj</td>
    <td>1213311</td>
    <td>2.47</td>
    </tr>
    <tr>
    <td>4</td>
    <td>Jihomoravsk&yacute; kraj</td>
    <td>1175025</td>
    <td>2.47</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Jihočesk&yacute; kraj</td>
    <td>637834</td>
    <td>2.20</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Karlovarsk&yacute; kraj</td>
    <td>297828</td>
    <td>1.68</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Zl&iacute;nsk&yacute; kraj</td>
    <td>584676</td>
    <td>1.54</td>
    </tr>
    <tr>
    <td>8</td>
    <td>Olomouck&yacute; kraj</td>
    <td>634718</td>
    <td>1.42</td>
    </tr>
    <tr>
    <td>9</td>
    <td>Plzeňsk&yacute; kraj</td>
    <td>576616</td>
    <td>1.39</td>
    </tr>
    <tr>
    <td>10</td>
    <td>Kr&aacute;lov&eacute;hradeck&yacute; kraj</td>
    <td>551421</td>
    <td>1.27</td>
    </tr>
    <tr>
    <td>11</td>
    <td>Pardubick&yacute; kraj</td>
    <td>516149</td>
    <td>1.16</td>
    </tr>
    <tr>
    <td>12</td>
    <td>Libereck&yacute; kraj</td>
    <td>439639</td>
    <td>1.14</td>
    </tr>
    <tr>
    <td>13</td>
    <td>Středočesk&yacute; kraj</td>
    <td>1326876</td>
    <td>1.06</td>
    </tr>
    <tr>
    <td>14</td>
    <td>Kraj Vysočina</td>
    <td>509475</td>
    <td>0.59</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <p>V obou tabulk&aacute;ch viditelně propadl Kraj Vysočina. <strong>Na chvostu</strong> se um&iacute;stila i <strong>oblast v&yacute;chodn&iacute;ch Čech </strong>(Libereck&yacute; kraj, Hradeck&yacute; kraj, Pardubick&yacute; kraj).</p>
    <p><strong>Středočesk&yacute; kraj</strong> je sice nadprůměrn&yacute; v celkov&eacute;m počtu čajoven, ale d&iacute;ky vysok&eacute;mu počtu obyvatel se pak po přepočtu na 100&nbsp;tis&iacute;c obyvatel ocit&aacute; <strong>na předposledn&iacute;m m&iacute;stě</strong>.</p>
    <h3>Jak si vedou krajsk&aacute; města?</h3>
    <B3_3/>
    <B3_4/>
    <B3_5/>
    <p>Při pohledu na absolutn&iacute; č&iacute;sla, pořad&iacute; krajů a jejich s&iacute;deln&iacute;ch měst v&iacute;cem&eacute;ně sed&iacute;. <strong>Nejv&iacute;ce se li&scaron;&iacute;</strong> pouze <strong>Karlovy</strong><strong>Vary</strong> od <strong>Karlovarsk&eacute;ho kraje</strong>, a to <strong>o 3 př&iacute;čky</strong>.</p>
    <p><strong>Prahu, kter&aacute; se um&iacute;stila na prvn&iacute; m&iacute;stě, n&aacute;sleduje Brno a Ostrava. </strong>Zde si tedy pouze Brno s Ostravou prohodily pozice (oproti pořad&iacute; jejich krajů). Na čtvrt&eacute;m a p&aacute;t&eacute;m m&iacute;stě se nach&aacute;z&iacute; <strong>&Uacute;st&iacute; nad Labem</strong> a <strong>Česk&eacute; Budějovice</strong>.</p>
    <figure>
    <table>
    <tbody>
    <tr>
    <td>Pořad&iacute;</td>
    <td>Krajsk&eacute; město</td>
    <td>Počet obyvatel</td>
    <td>Počet čajoven</td>
    </tr>
    <tr>
    <td>1</td>
    <td>Praha</td>
    <td>1267449</td>
    <td>39</td>
    </tr>
    <tr>
    <td>2</td>
    <td>Brno</td>
    <td>377028</td>
    <td>21</td>
    </tr>
    <tr>
    <td>3</td>
    <td>Ostrava</td>
    <td>292681</td>
    <td>15</td>
    </tr>
    <tr>
    <td>4</td>
    <td>&Uacute;st&iacute; nad Labem</td>
    <td>93248</td>
    <td>5</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Česk&eacute; Budějovice</td>
    <td>93513</td>
    <td>5</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Olomouc</td>
    <td>100154</td>
    <td>4</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Plzeň</td>
    <td>169858</td>
    <td>4</td>
    </tr>
    <tr>
    <td>8</td>
    <td>Karlovy Vary</td>
    <td>49326</td>
    <td>3</td>
    </tr>
    <tr>
    <td>9</td>
    <td>Zl&iacute;n</td>
    <td>75171</td>
    <td>3</td>
    </tr>
    <tr>
    <td>10</td>
    <td>Pardubice</td>
    <td>89638</td>
    <td>3</td>
    </tr>
    <tr>
    <td>11</td>
    <td>Hradec Kr&aacute;lov&eacute;</td>
    <td>92891</td>
    <td>2</td>
    </tr>
    <tr>
    <td>12</td>
    <td>Liberec</td>
    <td>103288</td>
    <td>2</td>
    </tr>
    <tr>
    <td>13</td>
    <td>Jihlava</td>
    <td>50714</td>
    <td>1</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <p>Rozd&iacute;ly pořad&iacute; podle počtu čajoven na 100&nbsp;tis&iacute;c obyvatel jsou v&scaron;ak rozmanitěj&scaron;&iacute;. Pr&aacute;vě při přepočtu se <strong>Praha propad&aacute; z prvn&iacute;ho až na 9. m&iacute;sto.</strong> Prvn&iacute; m&iacute;sto naopak z&iacute;sk&aacute;vaj&iacute; <strong>Karlovy Vary</strong>. Ač maj&iacute; velmi mal&eacute; množstv&iacute; čajoven, jde o nejmen&scaron;&iacute; krajsk&eacute; město a přepočet je tak vy&scaron;vihne nahoru.</p>
    <figure>
    <table>
    <tbody>
    <tr>
    <td>Pořad&iacute;</td>
    <td>Krajsk&eacute; město</td>
    <td>Počet obyvatel</td>
    <td>Index</td>
    </tr>
    <tr>
    <td>1</td>
    <td>Karlovy Vary</td>
    <td>49326</td>
    <td>6.08</td>
    </tr>
    <tr>
    <td>2</td>
    <td>Brno</td>
    <td>377028</td>
    <td>5.57</td>
    </tr>
    <tr>
    <td>3</td>
    <td>&Uacute;st&iacute; nad Labem</td>
    <td>93248</td>
    <td>5.36</td>
    </tr>
    <tr>
    <td>4</td>
    <td>Česk&eacute; Budějovice</td>
    <td>93513</td>
    <td>5.35</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Ostrava</td>
    <td>292681</td>
    <td>5.13</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Olomouc</td>
    <td>100154</td>
    <td>3.99</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Zl&iacute;n</td>
    <td>75171</td>
    <td>3.99</td>
    </tr>
    <tr>
    <td>8</td>
    <td>Pardubice</td>
    <td>89638</td>
    <td>3.35</td>
    </tr>
    <tr>
    <td>9</td>
    <td>Praha</td>
    <td>1267449</td>
    <td>3.08</td>
    </tr>
    <tr>
    <td>10</td>
    <td>Plzeň</td>
    <td>169858</td>
    <td>2.35</td>
    </tr>
    <tr>
    <td>11</td>
    <td>Hradec Kr&aacute;lov&eacute;</td>
    <td>92891</td>
    <td>2.15</td>
    </tr>
    <tr>
    <td>12</td>
    <td>Jihlava</td>
    <td>50714</td>
    <td>1.97</td>
    </tr>
    <tr>
    <td>13</td>
    <td>Liberec</td>
    <td>103288</td>
    <td>1.94</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <p>Stejně jako jejich kraje, <strong>i krajsk&aacute; města v&yacute;chodn&iacute;ch Čech </strong>(Liberec, Hradec Kr&aacute;lov&eacute; a Padubice)<strong> a Jihlava stoj&iacute; na pomysln&eacute;m chvostu.</strong> Nejhůře z nich v&scaron;ak vych&aacute;z&iacute; pr&aacute;vě Liberec.</p>
    <hr /><h2>Metodika z&iacute;sk&aacute;n&iacute; dat o počtu čajoven</h2>
    <p>Jako zdroj dat o čajovn&aacute;ch byla použita datab&aacute;ze Firmy.cz (stav k &uacute;noru 2020). Nutno přiznat, že jde o <strong>data orientačn&iacute;</strong>, kter&aacute; nezachycuj&iacute; realitu stoprocentně, jen ji přibližuj&iacute;. Celkově bude čajoven bude o trochu v&iacute;ce.</p>
    <p>D&aacute;le jsem pro &uacute;čely tohoto čl&aacute;nku vypustil<strong> mezin&aacute;rodn&iacute; srovn&aacute;n&iacute;</strong>, kter&eacute; <strong>nebylo s m&yacute;mi možnostmi provediteln&eacute;</strong>.</p>
    <h3>Co mě k tomu vedlo?</h3>
    <p>Spolehliv&aacute; data o počtu čajoven lze totiž jen těžko sh&aacute;nět. Byť jen za Česko, natož pak za zahraničn&iacute; země, abychom mohli prov&eacute;st srovn&aacute;n&iacute;. Veřejn&eacute; statistiky neexistuj&iacute;. Jedinou cestou bylo použ&iacute;t nějak&eacute; datab&aacute;ze.</p>
    <p><strong>Google Maps?</strong> Dostat z nich v&scaron;ak něco relevantn&iacute;ho mi při&scaron;lo nemožn&eacute;. A to už jen v kontextu Česka. Ano, zobrazoval sice spolehlivě nejv&iacute;c čajoven, ale je&scaron;tě aby ne. Do kategorie "čajovny" <strong>tu a tam přihodil nějakou cukr&aacute;rnu, kav&aacute;rnu a jednu prodejnu lyž&iacute;</strong>.</p>
    <p><strong>Yelp</strong> a <strong>TripAdvisor?</strong> Z nich jsem v&scaron;ak nedok&aacute;zala nějak rozumně (tak, abych nestr&aacute;vila věčnost vypisov&aacute;n&iacute;m) z&iacute;skat počet čajoven v jednotliv&yacute;ch zem&iacute;ch. A <strong>pro použit&iacute; v samotn&eacute;m Česku</strong> podle m&eacute;ho n&aacute;zoru až př&iacute;li&scaron; <strong>podhodnocovaly počty čajoven</strong> mimo Prahu.</p>
    <p>Nakonec jsem <strong>opustila my&scaron;lenku mezin&aacute;rodn&iacute;ho srovn&aacute;n&iacute;</strong>, pro teď. Než mě třeba (nebo v&aacute;s! můžete mi napsat na <a href="http://facebook.com/zemepisec">Facebook</a>) napadne nějak&aacute; metoda, kter&aacute; by nesežrala př&iacute;li&scaron; času. Přeci jen mus&iacute;m studovat a pracovat. <strong>Pro samotnou Českou republiku</strong> jsem v&scaron;ak na&scaron;la rovnou <strong>tři vhodn&eacute; adepty</strong>!</p>
    <h4>Datab&aacute;ze čajoven od Seznamu v&iacute;těz&iacute;!</h4>
    <p>Prve jsem narazila na datab&aacute;zi <a href="http://cajik.cz">cajik.cz</a>. Ta se jevila perfektně, samotn&aacute; Wikipedie ji citovala! Jen do doby, než jsem si v&scaron;imla, že byla naposledy aktualizov&aacute;na v roce 2013. A nav&iacute;c tam chyběla čajovna mnou nejobl&iacute;beněj&scaron;&iacute;. Semilsk&aacute; <a href="https://www.google.com/maps/place/%C4%8Cajovna+U+Robinsona/@50.6028321,15.3305377,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x470eb75f0a69a92f:0xd1f0225ac5534a72!2s%C4%8Cajovna+U+Robinsona!8m2!3d50.6028321!4d15.3327264!10e1!3m4!1s0x470eb75f0a69a92f:0xd1f0225ac5534a72!8m2!3d50.6028321!4d15.3327264">čajovna U Robinsona</a>. Takov&aacute; data jsem prostě nemohla akceptovat.</p>
    <p>Pak jsem tedy narazil na aktulizovan&eacute; firemn&iacute; katalogy <a href="https://www.zivefirmy.cz/?cz=1494">Živ&eacute;firmy.cz</a> a <a href="https://www.firmy.cz/Restauracni-a-pohostinske-sluzby/Cajovny">Firmy.cz</a>. Seznam&aacute;ck&aacute; datab&aacute;ze Firmy.cz, kterou použ&iacute;vaj&iacute; i mnou obl&iacute;ben&eacute; Mapy.cz, v&scaron;ak obsahovala v&iacute;ce čajoven. Naopak Živ&eacute;firmy neobsahovaly Robinsona. Netřeba snad d&aacute;l ř&iacute;kat, kterou z datab&aacute;z&iacute; jsem použila.</p>
    <p>Asi za 20 minut jsem měla informace, kolik čajoven je v jak&eacute;m městě. Nutno dodat, že to jistě nejsou v&scaron;echny čajovny v Česku, ale mysl&iacute;m, že jde st&aacute;le o velmi slu&scaron;nou aproximaci.</p>
    <h3>Data ke stažen&iacute;</h3>
    <p>Stejnou mapku, jako v z&aacute;hlav&iacute;, si můžete spl&aacute;cat v QGISu či ArcMapu. Stač&iacute; st&aacute;hnout:</p>
    <ul>
    <li><a href="https://www.arcdata.cz/produkty/geograficka-data/arccr-4-0">Mapov&yacute; podklad ArcČR&reg; 500</a> (shapefile administrativn&iacute;ch jednotek + statistick&aacute; data ČS&Uacute;)</li>
    <li><a href="https://drive.google.com/file/d/12nihBh1TxekAI9ozgCDN8wBjM3dUh4KB/view?usp=sharing">Excelovskou tabulku s počtem čajoven</a> (vych&aacute;z&iacute; z datab&aacute;ze Firmy.cz, &uacute;nor 2020)</li>
    </ul>
    <p>Snad se v&aacute;m čl&aacute;nek l&iacute;bil. Budu r&aacute;da za feedback. Pokud m&aacute;te nějak&eacute; připom&iacute;nky či koment&aacute;ře, diskutujte pros&iacute;m na <a href="http://facebook.com/zemepisec">Facebooku</a>. Hezk&yacute; den!</p>
    <hr />
    <p>Napsala Michaela Vojtěchovská, 22. října, 2020. <Link to="/blog/">Zobrazit další články na blogu</Link>.</p>

  </Layout>
)
